import { PropsWithChildren, useEffect } from 'react';
import { Container } from '@krakentech/coral';

import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';

import Alert from '../Alert';

import { StyledAlertContainer } from './index.styled';
import { AnimatedAlertProps } from './index.types';

const AnimatedAlert = ({
  alertStatus = ANIMATED_ALERT_STATUS.CLOSED,
  handleAlertClose,
  handleAlertAutoclose,
  severity = 'success',
  containerPadding,
  relativelyPositioned = false,
  alertClosureTimerLength = 5000,
  children,
}: AnimatedAlertProps & PropsWithChildren) => {
  useEffect(() => {
    const closeHandler = handleAlertAutoclose || handleAlertClose;

    if (alertStatus === ANIMATED_ALERT_STATUS.OPEN) {
      const alertTimer = setTimeout(() => {
        closeHandler();
      }, alertClosureTimerLength);

      return () => {
        clearTimeout(alertTimer);
      };
    }
  }, [
    handleAlertClose,
    handleAlertAutoclose,
    alertClosureTimerLength,
    alertStatus,
  ]);

  return (
    <>
      {alertStatus !== ANIMATED_ALERT_STATUS.CLOSED && children && (
        <StyledAlertContainer
          alertStatus={alertStatus}
          relativelyPositioned={relativelyPositioned}
        >
          <Container
            marginBottom="lg"
            marginX="auto"
            maxWidth="lg"
            padding={containerPadding}
          >
            <Alert severity={severity} onClose={handleAlertClose}>
              {children}
            </Alert>
          </Container>
        </StyledAlertContainer>
      )}
    </>
  );
};

export default AnimatedAlert;
