import { CREATE_NEW_ACCOUNT } from '@/consts/createNewAccount';
import { MOVE_HOME } from '@/consts/moveHome';

import { encodeQueryParams } from './urlHelpers';

export const INTERNAL_PATHS = {
  HOME: {
    path: '/',
    title: 'Dashboard',
    name: 'overview',
  },
  LOGIN: {
    path: '/login',
    title: 'Log in to your account',
    name: 'login',
  },
  REGISTER_FOR_ONLINE_ACCOUNT_YOUR_ACCOUNT: {
    path: '/register-for-online-account/your-account',
    title: 'Your account | Register for an online account',
    name: 'your-account',
  },
  REGISTER_FOR_ONLINE_ACCOUNT_ABOUT_YOU: {
    path: '/register-for-online-account/about-you',
    title: 'About you | Register for an online account',
    name: 'about-you',
  },
  REGISTER_FOR_ONLINE_ACCOUNT_SECURITY: {
    path: '/register-for-online-account/security',
    title: 'Security | Register for an online account',
    name: 'security',
  },
  REGISTER_FOR_ONLINE_ACCOUNT_LOGIN_DETAILS: {
    path: '/register-for-online-account/login-details',
    title: 'Login details | Register for an online account',
    name: 'login-details',
  },
  REGISTER_FOR_ONLINE_ACCOUNT_SUCCESS: {
    path: '/register-for-online-account/success',
    title: 'Success | Register for an online account',
    name: 'success',
  },
  FORGOTTEN_EMAIL_YOUR_ACCOUNT: {
    path: '/forgotten-email/your-account',
    title: 'Your account | Forgotten email',
    name: 'your-account',
  },
  FORGOTTEN_EMAIL_ABOUT_YOU: {
    path: '/forgotten-email/about-you',
    title: 'Select your name | Forgotten email',
    name: 'about-you',
  },
  FORGOTTEN_EMAIL_SECURITY: {
    path: '/forgotten-email/security',
    title: 'Security | Forgotten email',
    name: 'security',
  },
  FORGOTTEN_EMAIL_REGISTER_YOUR_EMAIL: {
    path: '/forgotten-email/register-your-email',
    title: 'Register your email | Forgotten email',
    name: 'register-your-email',
  },
  FORGOTTEN_EMAIL_YOUR_EMAIL_ADDRESS: {
    path: '/forgotten-email/your-email-address',
    title: 'Enter your email | Forgotten email',
    name: 'your-email',
  },
  FORGOTTEN_EMAIL_SUCCESS: {
    path: '/forgotten-email/success',
    title: 'Success | Forgotten email',
    name: 'success',
  },
  FORGOTTEN_PASSWORD: {
    path: '/forgotten-password',
    name: 'forgotten-password',
    title: 'Forgotten password',
  },
  FORGOTTEN_PASSWORD_SUCCESS: {
    path: '/forgotten-password/success',
    name: 'forgotten-password-success',
    title: 'Success | Forgotten password',
  },
  RESET_PASSWORD: {
    createPath: ({ userId, token }: { userId: string; token: string }) =>
      `/reset-password/${userId}/${token}`,
    name: 'reset-password',
    title: 'Reset password',
  },
  RESET_PASSWORD_SUCCESS: {
    path: '/reset-password/success',
    name: 'reset-password-success',
    title: 'Success | Reset password',
  },
  SETTINGS: {
    path: '/settings',
    title: 'Settings',
    name: 'settings',
  },
  UPDATE_PERSONAL_DETAILS: {
    path: '/settings/update-personal-details',
    title: 'Edit personal details | Settings',
    name: 'update-personal-details',
  },
  UPDATE_BILLING_ADDRESS: {
    path: '/settings/update-billing-address',
    title: 'Billing address | Settings',
    name: 'update-billing-address',
  },
  SECURITY: {
    path: '/settings/security',
    title: 'Security | Settings',
    name: 'security',
  },
  UPDATE_COMMUNICATIONS_PREFERENCES: {
    path: '/settings/update-communication-preferences',
    title: 'Communication preferences | Settings',
    name: 'update-communication-preferences',
  },
  PRIORITY_SERVICES_REGISTER: {
    path: '/settings/priority-services-register',
    title: 'Priority Services Register | Settings',
    name: 'priority-services-register',
  },
  BILLS: {
    path: '/bills',
    title: 'Bills',
    name: 'bills',
  },
  SINGLE_BILL_DOWNLOAD: {
    createPath: ({ id, billType }: { id: string; billType: string }) =>
      `/bills/${billType}/${id}`,
    title: 'Download bill',
    name: 'single-bill-download',
  },
  SUBMIT_METER_READING: {
    createPath: ({ meterId }: { meterId: string }) =>
      `/usage/${meterId}/submit-new-reading`,
    title: 'Enter a reading | Submit meter reading',
    name: 'submit-new-reading',
  },
  SUBMIT_METER_READING_SUCCESS: {
    createPath: ({ meterId }: { meterId: string }) =>
      `/usage/${meterId}/success`,
    title: 'Meter reading submitted | Submit meter reading',
    name: 'submit-new-reading-success',
  },
  USAGE: {
    path: '/usage',
    title: 'Usage',
    name: 'usage',
  },
  MAKE_ONLINE_PAYMENT: [
    {
      path: '/make-online-payment',
      title: 'Make an online payment',
      name: 'make-online-payment-payment-amount',
      stepperAriaLabel: 'Step 1: Choose amount to pay',
    },
    {
      path: '/make-online-payment/card-details',
      title: 'Enter your card details | Make an online payment',
      name: 'make-online-payment-card-details',
      stepperAriaLabel: 'Step 2: Enter card details',
    },
    {
      path: '/make-online-payment/success',
      title: 'Success | Make an online payment',
      name: 'make-online-payment-success',
    },
  ],
  MANAGE_PAYMENTS: {
    path: '/manage-payments',
    title: 'Manage payments',
    name: 'manage-payments',
  },
  NECTAR_CONNECT: {
    path: '/nectar-connect',
    title: 'Nectar connect',
    name: 'nectar-connect',
  },
  CHANGE_BANK_DETAILS: {
    path: '/change-bank-details',
    title: 'Change bank details',
    name: 'change-bank-details',
  },
  CHANGE_BANK_DETAILS_SUCCESS: {
    path: '/change-bank-details/success',
    title: 'Success | Change bank details',
    name: 'change-bank-details-success',
  },
  CHANGE_PAYMENT_SCHEDULE: {
    path: '/change-payment-schedule',
    title: 'Change payment schedule',
    name: 'change-payment-schedule',
  },
  CHANGE_PAYMENT_SCHEDULE_SUCCESS: {
    path: '/change-payment-schedule/success',
    title: 'Success | Change payment schedule',
    name: 'change-payment-schedule-success',
  },
  SET_UP_DIRECT_DEBIT: [
    {
      path: '/direct-debit/payment-frequency',
      title: 'Payment frequency | Direct Debit set up',
      name: 'direct-debit-payment-frequency',
    },
    {
      path: '/direct-debit/bank-details',
      title: 'Enter your bank details | Direct Debit set up',
      name: 'direct-debit-bank-details',
    },
    {
      path: '/direct-debit/success',
      title: 'Success | Direct Debit set up',
      name: 'direct-debit-success',
    },
  ],
  // The journey users go down if they are sent a welcome letter and scan the QR code in the letter. It's for customers who, when they are migrated, don't have an email address.
  SIGN_UP: {
    // The first page, where the user sees their first name and address
    CHECK_USER_DETAILS: {
      path: '/sign-up/',
      title: "Let's get started | Sign up",
      name: 'lets-get-started',
    },
    // The page where the user enters either their mobile number or DOB to verify their identity
    VERIFY_IDENTITY: {
      path: '/sign-up/verify-identity',
      title: "Let's verify who you are | Sign up",
      name: 'sign-up-verify-identity',
    },
    // Alternate page to the VERIFY_IDENTITY one, where the user goes if we have neither a DOB nor a mobile number for them on Kraken
    CONTACT_US: {
      path: '/sign-up/contact-us',
      title: 'Contact us | Sign up',
      name: 'sign-up-contact-us',
    },
    // The page where the user enters their email address and password
    SET_UP_ACCOUNT: {
      path: '/sign-up/set-up-account',
      title: 'Set up account | Sign up',
      name: 'set-up-account',
    },
    // Success page
    SUCCESS: {
      path: '/sign-up/success',
      title: 'Check your inbox | Sign up',
      name: 'sign-up-success',
    },
    // For if the user visits any of the above pages with an expired token in the URL
    EXPIRED_LINK: {
      path: '/sign-up/expired-link',
      title: 'Link expired | Sign up',
      name: 'sign-up-expired-link',
    },
  },
  MOVE_HOME: [
    {
      path: '/move-home/move-out-date',
      title: 'Moving out date | Move home',
      name: MOVE_HOME.MOVE_OUT,
    },
    {
      path: '/move-home/final-reading',
      title: 'Final meter read | Move home',
      name: MOVE_HOME.FINAL_READ,
    },
    {
      path: '/move-home/new-address',
      title: 'Your new address | Move home',
      name: MOVE_HOME.NEW_HOME,
    },
    {
      path: '/move-home/review',
      title: 'Review and confirm | Move home',
      name: MOVE_HOME.REVIEW,
    },
    {
      path: '/move-home/success',
      title: 'Success | Move home',
      name: MOVE_HOME.SUCCESS,
    },
  ],
  MASQUERADE: {
    createPath: ({
      userId,
      masqueradeToken,
    }: {
      userId: string;
      masqueradeToken: string;
    }) => `/masquerade/${userId}/${masqueradeToken}`,
  },
  SCOPED_TOKEN: {
    createPath: ({ token }: { token: string }) => `/scoped/${token}`,
  },
  CUSTOMER_FEEDBACK: {
    PROVIDE_FEEDBACK: {
      createQueryString: ({
        formId,
        feedbackId,
      }: {
        formId: string;
        feedbackId: string;
      }) => encodeQueryParams({ formId, feedbackId }),
      path: '/customer-feedback',
      title: 'Customer feedback',
      name: 'customer-feedback',
    },
    SUCCESS: {
      path: '/customer-feedback/success',
      title: 'Success | Customer feedback',
      name: 'customer-feedback-success',
    },
    EXPIRED_LINK: {
      path: '/customer-feedback/expired-link',
      title: 'Link expired | Customer feedback',
      name: 'customer-feedback-link',
    },
  },
  CREATE_NEW_ACCOUNT: [
    {
      path: '/create-new-account/new-home',
      title: 'Your address | Create new account',
      name: CREATE_NEW_ACCOUNT.NEW_HOME,
    },
    {
      path: '/create-new-account/your-details',
      title: 'Your details | Create new account',
      name: CREATE_NEW_ACCOUNT.YOUR_DETAILS,
    },
    {
      path: '/create-new-account/your-meter',
      title: 'Your meter | Create new account',
      name: CREATE_NEW_ACCOUNT.YOUR_METER,
    },
    {
      path: '/create-new-account/review',
      title: 'Review your details | Create new account',
      name: CREATE_NEW_ACCOUNT.REVIEW,
    },
    {
      path: '/create-new-account/success',
      title: 'Success | Create new account',
      name: CREATE_NEW_ACCOUNT.SUCCESS,
    },
  ],
  APPLY_FOR_METER_STOP_TAP: {
    path: '/apply-for-meter/stop-tap',
    title: 'Your stop tap | Switch to a water meter',
    name: 'Stop tap',
  },
  APPLY_FOR_METER_YOUR_HOME: {
    path: '/apply-for-meter/your-home',
    title: 'Your home | Switch to a water meter',
    name: 'Your home',
  },
  APPLY_FOR_METER_BILLING_PLAN: {
    path: '/apply-for-meter/billing-plan',
    title: 'Your billing plan | Switch to a water meter',
    name: 'Billing plan',
  },
  APPLY_FOR_METER_APPOINTMENT: {
    path: '/apply-for-meter/appointment',
    title: 'Book an appointment | Switch to a water meter',
    name: 'Appointment',
  },
  APPLY_FOR_METER_REVIEW: {
    path: '/apply-for-meter/review',
    title: 'Review your appointment | Switch to a water meter',
    name: 'Review',
  },
  APPLY_FOR_METER_OVERVIEW: {
    path: '/apply-for-meter/overview',
    title: 'Overview | Switch to a water meter',
    name: 'Overview',
  },
  APPLY_FOR_METER_SUCCESS: {
    path: '/apply-for-meter/success',
    title: 'Success | Switch to a water meter',
    name: 'Success',
  },
  APPLY_FOR_METER_STOP: {
    path: '/apply-for-meter/stop',
    title: 'Stop | Switch to a water meter',
    name: 'Stop',
  },
  ERROR: { path: '/error', title: 'Uh oh..', name: 'error' },
  MAINTENANCE: {
    path: '/maintenance',
    title: 'Maintenance',
    name: 'maintenance',
  },
};
