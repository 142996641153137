import * as yup from 'yup';

export const latestReadingSchema = yup.object({
  account: yup.object({
    properties: yup.array(
      yup.object({
        activeWaterMeters: yup.array(
          yup.object({
            readings: yup.object({
              edges: yup.array(
                yup.object({
                  node: yup.object({
                    id: yup.string().required(),
                  }),
                })
              ),
            }),
          })
        ),
      })
    ),
  }),
});

export const meterReadingsSchema = yup.object({
  account: yup.object({
    properties: yup.array(
      yup.object({
        activeWaterMeters: yup.array(
          yup.object({
            readings: yup.object({
              id: yup.string(),
              numberOfDigits: yup.number(),
              edges: yup.array(
                yup.object({
                  node: yup.object({
                    readingDate: yup.date(),
                    valueCubicMetres: yup.number(),
                    source: yup.string(),
                  }),
                })
              ),
            }),
          })
        ),
      })
    ),
  }),
});

export const submitMeterReadingSchema = yup.object({
  submitMeterReading: yup.object({
    reading: yup.object({
      id: yup.string().required(),
      valueCubicMetres: yup.number().required(),
      isHeld: yup.boolean().required(),
      possibleErrors: yup.array(
        yup.object({
          message: yup.string(),
        })
      ),
    }),
    possibleErrors: yup.array(
      yup.object({
        message: yup.string(),
      })
    ),
  }),
});

export const meterDetailsSchema = yup.object({
  account: yup.object({
    properties: yup.array(
      yup.object({
        activeWaterMeters: yup.array(
          yup.object({
            serialNumber: yup.string().required(),
            numberOfDigits: yup.number().required(),
            readings: yup.object({
              edges: yup.array(
                yup.object({
                  node: yup.object({
                    readingDate: yup.date(),
                    valueCubicMetres: yup.number(),
                    source: yup.string(),
                  }),
                })
              ),
            }),
          })
        ),
      })
    ),
  }),
});

export const meterReadingSuccessSchema = yup.object({
  account: yup.object({
    properties: yup.array(
      yup.object({
        activeWaterMeters: yup.array(
          yup.object({
            numberOfDigits: yup.number().required(),
            readings: yup.object({
              edges: yup.array(
                yup.object({
                  node: yup.object({
                    valueCubicMetres: yup.number(),
                  }),
                })
              ),
            }),
          })
        ),
      })
    ),
  }),
});

export const hasActiveWaterMeterSchema = yup.object({
  account: yup.object({
    properties: yup.array(
      yup.object({
        activeWaterMeters: yup.array(
          yup.object({
            id: yup.string(),
          })
        ),
      })
    ),
  }),
});

export const isOnMeteredTariffSchema = yup.object({
  account: yup.object({
    isMeasured: yup.boolean().nullable(),
  }),
});
