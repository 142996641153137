// A component to use on all forms instead of the <Button> component directly. This component is a wrapper around the Coral Button component, which standardises some styling and adds a loading state.
import { Button } from '@krakentech/coral';

import ButtonLoadingLabel from '@/components/ButtonLoadingLabel';

import { FormSubmitButtonProps } from './index.types';

const FormSubmitButton = ({
  loading = false,
  children,
  disabled,
  icon,
  attributes,
}: FormSubmitButtonProps) => (
  <Button
    fullWidth
    type="submit"
    size="medium"
    loading={loading}
    disabled={disabled || loading}
    loadingLabel={
      <ButtonLoadingLabel>
        {icon && <div>{icon}</div>}

        {children}
      </ButtonLoadingLabel>
    }
    attributes={attributes}
  >
    {icon && <div>{icon}</div>}
    <span>{children}</span>
  </Button>
);

export default FormSubmitButton;
