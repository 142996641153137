import { useRouter } from 'next/router';
import { Button } from '@krakentech/coral';
import { IconArrowLeft } from '@krakentech/icons';

const BackButton = ({
  previousPageName,
  backButtonHref,
}: {
  previousPageName?: string;
  backButtonHref?: string;
}) => {
  const { back } = useRouter();

  return (
    <Button
      color="secondary"
      size="small"
      title={`Back to ${backButtonHref ? previousPageName : 'previous page'}`}
      variant="ghost"
      href={backButtonHref}
      onClick={!backButtonHref ? () => back() : () => {}}
    >
      <IconArrowLeft size={16} />
    </Button>
  );
};

export default BackButton;
