import { Grid } from '@krakentech/coral';
import { IconGBP } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useHasActiveSocialAgreement } from '@/hooks/accounts/useHasActiveSocialAgreement';
import { INTERNAL_PATHS } from '@/utils/urls';

type SetupDirectDebitCardProps = {
  isLoading: boolean;
  isError: boolean;
};

const SetupDirectDebitCard = ({
  isLoading,
  isError,
}: SetupDirectDebitCardProps) => {
  const {
    data: hasActiveSocialAgreement,
    isLoading: hasActiveSocialAgreementLoading,
    isError: hasActiveSocialAgreementError,
  } = useHasActiveSocialAgreement();

  const combinedIsLoading = isLoading || hasActiveSocialAgreementLoading;
  const combinedIsError = isError || hasActiveSocialAgreementError;

  const href = INTERNAL_PATHS.SET_UP_DIRECT_DEBIT[0].path;

  if (hasActiveSocialAgreement) {
    return null;
  }

  return (
    <Grid.Item>
      <ActionCard
        href={href}
        title={'Set up a Direct Debit'}
        titleComponent="p"
        icon={<IconGBP size={32} />}
        isLoading={combinedIsLoading}
        isError={combinedIsError}
      />
    </Grid.Item>
  );
};

export default SetupDirectDebitCard;
