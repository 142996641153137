// This component contains a Frankly Wild Table, which needs to look completely different on mobile. On desktop and tablet, it is nice and normal. On mobile, it requires: 1) no th labels, 2) two different types of rows, one to show just the data and one for everything else, and 3) some of the rows need to have less height. The Table component is set up to handle these options.
import { useState } from 'react';
import {
  Button,
  Container,
  Stack,
  Tooltip,
  Visibility,
} from '@krakentech/coral';

import PIITypography from '@/components/PII/PIITypography';
import {
  TRANSACTION_TYPE_NAMES,
  TRANSACTION_TYPES,
} from '@/consts/transactionTypes';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useLedgers } from '@/hooks/billsAndPayments/useLedgers';
import { usePayments } from '@/hooks/billsAndPayments/usePayments';
import { usePagination } from '@/hooks/utils/usePagination';
import {
  formatCurrency,
  formatCurrencyWithoutMinusSymbol,
} from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';
import { getCorrectLedgerId } from '@/utils/ledgers';

import MutedText from '../../MutedText';
import NoDataMessage from '../../NoDataMessage';
import PageError from '../../PageError';
import Paginator from '../../Paginator';
import { Body1Skeleton, Body2Skeleton } from '../../Skeletons';
import Table from '../../Table';

import { StyledMobilePaymentDate, StyledTransaction } from './index.styled';
import { MobileRow } from './index.types';

const PaymentsList = () => {
  const [displayBalanceCarriedForward, setDisplayBalanceCarriedForward] =
    useState(true);

  const { accountNumber } = useAccountNumberContext();
  const {
    data: ledgersData,
    isLoading: ledgersLoading,
    error: ledgersError,
  } = useLedgers();

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    isError: paymentsError,
    hasNextPage,
    fetchNextPage,
  } = usePayments({
    accountNumber,
    ledgerId: ledgersData?.length && getCorrectLedgerId(ledgersData),
  });

  const { paginatedData, totalNumberOfItems, numberOfDisplayedItems } =
    usePagination(paymentsData);

  if (
    paymentsLoading ||
    ledgersLoading ||
    typeof paginatedData === 'undefined'
  ) {
    return <LoadingState />;
  }

  if (paymentsError || ledgersError) {
    return <PageError />;
  }

  const getHumanReadableTransactionType = (transactionType: string) => {
    return TRANSACTION_TYPE_NAMES[transactionType.toUpperCase()];
  };

  const transactionIsNotChargeOrRefund = (transactionType: string) => {
    return (
      transactionType !== TRANSACTION_TYPES.CHARGE &&
      transactionType !== TRANSACTION_TYPES.REFUND
    );
  };

  const getBalanceCarriedForwardCreditDebitIndicator = (amount: number) => {
    if (amount > 0) {
      return '(cr)';
    } else if (amount < 0) {
      return '(dr)';
    } else {
      return '';
    }
  };

  const getDataForMobileTable = () => {
    const mobileData: Array<MobileRow> = [];

    paginatedData.forEach(({ node }) => {
      const { postedDate, __typename, amount, balanceCarriedForward } = node;

      mobileData.push({
        left: postedDate ? (
          <PIITypography variant="body2">
            <StyledMobilePaymentDate>
              {formatFullDateShortMonth(new Date(postedDate))}
            </StyledMobilePaymentDate>
          </PIITypography>
        ) : (
          ''
        ),
        right: '',
      });
      mobileData.push({
        left: __typename ? (
          <PIITypography>
            {getHumanReadableTransactionType(__typename)}
          </PIITypography>
        ) : (
          ''
        ),
        right:
          typeof amount === 'number' &&
          typeof balanceCarriedForward === 'number' ? (
            <>
              <PIITypography textAlign="right">
                <StyledTransaction
                  isPositive={transactionIsNotChargeOrRefund(__typename)}
                >
                  {formatCurrency(amount)}{' '}
                  {transactionIsNotChargeOrRefund(__typename) && `cr`}
                </StyledTransaction>
              </PIITypography>
              {displayBalanceCarriedForward && (
                <MutedText
                  textAlign="right"
                  textWrap="nowrap"
                  variant="caption"
                  containsPII
                >
                  Balance:{' '}
                  {formatCurrencyWithoutMinusSymbol(balanceCarriedForward)}{' '}
                  {getBalanceCarriedForwardCreditDebitIndicator(
                    balanceCarriedForward
                  )}
                </MutedText>
              )}
            </>
          ) : (
            ''
          ),
      });
    });

    return mobileData;
  };

  return (
    <>
      {paginatedData.length ? (
        <>
          <Visibility display="none" md={{ display: 'block' }}>
            <Table
              columns={[
                {
                  name: 'Date',
                  width: 16.66,
                },
                {
                  name: 'Type',
                  width: 50,
                },
                {
                  name: 'Amount',
                  width: 16.66,
                },
                {
                  name: 'Balance',
                  width: 16.66,
                  tooltip:
                    "If 'dr' (debit) is displayed after the balance, this is money you owe us. If 'cr' (credit) is displayed, this amount will go towards your next bill",
                },
              ]}
              data={paginatedData.map(
                ({
                  node: {
                    postedDate,
                    __typename,
                    amount,
                    balanceCarriedForward,
                  },
                }) => ({
                  date: postedDate ? (
                    <PIITypography textWrap="nowrap">
                      {formatFullDateShortMonth(new Date(postedDate))}
                    </PIITypography>
                  ) : (
                    ''
                  ),
                  type: __typename ? (
                    <PIITypography>
                      {getHumanReadableTransactionType(__typename)}
                    </PIITypography>
                  ) : (
                    ''
                  ),
                  amount:
                    typeof amount === 'number' ? (
                      <PIITypography textWrap="nowrap">
                        <StyledTransaction
                          isPositive={transactionIsNotChargeOrRefund(
                            __typename
                          )}
                        >
                          {formatCurrency(amount)}{' '}
                          {transactionIsNotChargeOrRefund(__typename) && `cr`}
                        </StyledTransaction>
                      </PIITypography>
                    ) : (
                      ''
                    ),
                  balance:
                    typeof balanceCarriedForward === 'number' ? (
                      <PIITypography textWrap="nowrap">
                        <strong>
                          {formatCurrencyWithoutMinusSymbol(
                            balanceCarriedForward
                          )}
                        </strong>{' '}
                        {getBalanceCarriedForwardCreditDebitIndicator(
                          balanceCarriedForward
                        )}
                      </PIITypography>
                    ) : (
                      ''
                    ),
                })
              )}
            />
          </Visibility>

          <Visibility md={{ display: 'none' }}>
            <Container
              component="section"
              marginX="auto"
              paddingBottom="sm"
              paddingX="md"
            >
              <Stack justifyContent="flex-end" gap="none">
                <Button
                  variant="text"
                  onClick={() =>
                    setDisplayBalanceCarriedForward(
                      !displayBalanceCarriedForward
                    )
                  }
                >
                  {displayBalanceCarriedForward
                    ? 'Hide balance'
                    : 'Show balance'}
                </Button>
                <Tooltip title="If 'dr' (debit) is displayed after the balance, this is money you owe us. If 'cr' (credit) is displayed, this amount will go towards your next bill" />
              </Stack>
            </Container>
            <Table
              columns={[
                {
                  name: '',
                  width: 50,
                },
                {
                  name: '',
                  width: 50,
                },
              ]}
              data={paginatedData ? getDataForMobileTable() : []}
              oddRowsShorter
            />
          </Visibility>

          <Paginator
            buttonLabel="Show more payments"
            totalNumberOfItems={totalNumberOfItems}
            numberOfItemsDisplayed={numberOfDisplayedItems}
            hasNextPage={hasNextPage}
            onButtonClick={fetchNextPage}
          />
        </>
      ) : (
        <NoDataMessage
          title="No previous payments available"
          body="Previous payments cannot be displayed - please come back when a payment has been made into or from your account"
        />
      )}
    </>
  );
};

const LoadingState = () => (
  <>
    <Visibility display="none" md={{ display: 'block' }}>
      <Table
        isLoading
        columns={[
          {
            name: '',
            width: 16.66,
          },
          {
            name: '',
            width: 50,
          },
          {
            name: '',
            width: 16.66,
          },
          {
            name: '',
            width: 16.66,
          },
        ]}
      />
    </Visibility>

    <Visibility md={{ display: 'none' }}>
      <Table
        columns={[
          {
            name: '',
            width: 50,
          },
          {
            name: '',
            width: 50,
          },
        ]}
        data={[
          {
            left: <Body2Skeleton />,
            right: '',
          },
          {
            left: <Body1Skeleton />,
            right: <Body1Skeleton />,
          },
          {
            left: <Body2Skeleton />,
            right: '',
          },
          {
            left: <Body1Skeleton />,
            right: <Body1Skeleton />,
          },
        ]}
        oddRowsShorter
      />
    </Visibility>
  </>
);

export default PaymentsList;
