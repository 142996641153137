import {
  Button,
  Stack,
  useCoralBreakpoints,
  Visibility,
} from '@krakentech/coral';
import { useIsClient } from '@krakentech/utils';

import { QuestionMarkIcon } from '@/public/images';
import { isStw } from '@/utils/environment';

const ContactUsButton = () => {
  const { isMaxMd: isMobile } = useCoralBreakpoints();
  const isClient = useIsClient();

  const label = isStw() ? 'Help' : 'Contact us';
  const href = isStw()
    ? 'https://www.stwater.co.uk/search/'
    : process.env.NEXT_PUBLIC_HELP_LINK;

  return (
    <Button
      href={href}
      color="tertiary"
      size={isClient && isMobile ? 'medium' : 'small'}
      target="_blank"
      title={label}
    >
      <Stack gap="sm" alignItems="center">
        <QuestionMarkIcon />
        <Visibility display="none" md={{ display: 'flex' }}>
          {label}
        </Visibility>
      </Stack>
    </Button>
  );
};

export default ContactUsButton;
