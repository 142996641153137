import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { FormTextFieldWithDescription } from '@/components';

import type { PhoneFieldTypeProps } from './index.types';

const PhoneNumberField = ({
  name,
  label,
  isError,
  errorMessage,
  telephoneHelperText = 'Number must start with +44 or 0',
  disabled = false,
}: PhoneFieldTypeProps) => {
  const { setFieldError } = useFormikContext();

  useEffect(() => {
    if (isError) {
      return setFieldError(name, errorMessage);
    }
  }, [isError, setFieldError]);

  return (
    <FormTextFieldWithDescription
      name={name}
      label={label}
      description={telephoneHelperText}
      disabled={disabled}
    />
  );
};

export default PhoneNumberField;
