import { PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@krakentech/coral';

import { piiDataAttributes } from '../constants';

const PIITypography = ({
  children,
  ...props
}: TypographyProps & PropsWithChildren) => (
  <Typography
    {...props}
    attributes={{
      ...props.attributes,
      ...piiDataAttributes,
    }}
  >
    {children}
  </Typography>
);

export default PIITypography;
