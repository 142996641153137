import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import NextImage from 'next/image';
import { Stack, Typography } from '@krakentech/coral';

import { EllipsisIcon } from '@/public/images';

import {
  Panel,
  StyledButton,
  StyledEllipsisButton,
  StyledEllipsisButtonContainer,
} from './index.styled';

export type DropdownItem = {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
};

type EllipsisDropdownProps = {
  items: DropdownItem[];
};

const EllipsisDropdown: FC<PropsWithChildren<EllipsisDropdownProps>> = ({
  items,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLButtonElement | HTMLAnchorElement | null)[]>([]);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    setFocusedItemIndex(0);
  };

  const handleEllipsisInteraction = (
    event: ReactMouseEvent | ReactKeyboardEvent
  ) => {
    const isClick = event.type === 'click';
    const isKeydown = event.type === 'keydown';

    if (isClick) {
      event.preventDefault();
      toggleDropdown();
      return;
    }

    if (isKeydown) {
      const keyEvent = event as ReactKeyboardEvent;
      const { key } = keyEvent;

      if (key === 'Enter' || key === ' ') {
        keyEvent.preventDefault();
        toggleDropdown();
      } else if (key === 'ArrowDown' || key === 'ArrowUp') {
        keyEvent.preventDefault();
        setDropdownOpen(true);
        setFocusedItemIndex((prevIndex) => {
          const increment = key === 'ArrowDown' ? 1 : -1;
          return (prevIndex + increment + items.length) % items.length;
        });
      }
    }
  };

  /*
    Handles clicks outside the dropdown to close it unless the click is within
    the dropdown container or the panel. This prevents the dropdown from closing
    when interacting with elements within the dropdown.
   */
  const handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    if (
      containerRef.current?.contains(targetElement) ||
      panelRef.current?.contains(targetElement)
    ) {
      return;
    }
    setDropdownOpen(false);
  };

  const handleItemBlur = () => {
    requestAnimationFrame(() => {
      if (
        containerRef.current &&
        !containerRef.current.contains(document.activeElement) &&
        !panelRef.current?.contains(document.activeElement)
      ) {
        setDropdownOpen(false);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);

      // Automatically shift focus to the first dropdown element when the dropdown opens.
      requestAnimationFrame(() => {
        if (itemRefs.current[0]) {
          itemRefs.current[0].focus();
        }
      });
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleItemClick = (childOnClick: () => void) => {
    setDropdownOpen(false);
    childOnClick();
  };

  const handleItemKeyDown = (
    event: ReactKeyboardEvent,
    childOnClick: () => void
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleItemClick(childOnClick);
    } else if (event.key === 'Escape') {
      setDropdownOpen(false);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedItemIndex(
        (prevIndex) => (prevIndex - 1 + items.length) % items.length
      );
    }
  };

  return (
    <div>
      <StyledEllipsisButtonContainer
        aria-label="More options"
        ref={containerRef}
      >
        <StyledEllipsisButton
          data-testid="ellipsis-dropdown-button"
          aria-label="More options"
          size="xs"
          variant="text"
          onClick={handleEllipsisInteraction}
          tabIndex={0}
        >
          <NextImage src={EllipsisIcon} alt="" height={18} width={18} />
        </StyledEllipsisButton>
        {dropdownOpen && (
          <Panel role="menu" aria-label="Dropdown menu" ref={panelRef}>
            <Stack alignItems="center" gap="xs" direction="vertical">
              {items?.map((item, index) => (
                <StyledButton
                  key={index}
                  onClick={() => {
                    handleItemClick(item.onClick);
                  }}
                  onKeyDown={(event: ReactKeyboardEvent<HTMLButtonElement>) =>
                    handleItemKeyDown(event, item.onClick)
                  }
                  onBlur={() => handleItemBlur()}
                  startIcon={item.icon}
                  color="tertiary"
                  variant="text"
                  tabIndex={index === focusedItemIndex ? 0 : -1}
                  ref={(element: any) => (itemRefs.current[index] = element)}
                  role="menuitem"
                >
                  <Typography variant="h3" color="primary" whiteSpace="nowrap">
                    {item.label}
                  </Typography>
                </StyledButton>
              ))}
            </Stack>
          </Panel>
        )}
      </StyledEllipsisButtonContainer>
    </div>
  );
};

export default EllipsisDropdown;
