import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';

import { YourAccountForm } from '@/components/forms';
import { YourAccountFormValues } from '@/components/forms/YourAccountForm/index.types';
import {
  useUserRegistrationContext,
  USER_REGISTRATION_JOURNEY_KEY_OPTIONS,
} from '@/context/UserRegistrationContext';
import { useAccountIdentification } from '@/hooks/auth/useAccountIdentification';
import { useUserRegistrationStatus } from '@/hooks/auth/useUserRegistrationStatus';
import { useVerifyIdentity } from '@/hooks/auth/useVerifyIdentity';
import { userRegistrationJourneyConfig } from '@/utils/identifyAndVerify';
import { INTERNAL_PATHS } from '@/utils/urls';

const AccountFinder = ({
  journeyKey,
}: {
  journeyKey: USER_REGISTRATION_JOURNEY_KEY_OPTIONS;
}) => {
  const { push } = useRouter();

  const {
    mutate,
    isSuccess: isVerifyIdentitySuccess,
    isError: isVerifyIdentityError,
  } = useVerifyIdentity();

  const {
    accountNumber,
    postcode,
    surname,
    setAccountFields,
    setSelectedAccountUserName,
    setScopedToken,
    scopedToken,
    setHasDateOfBirth,
    setHasMobileNumber,
    setBlackholeAccountUsers,
    setSelectedUserId,
  } = useUserRegistrationContext();

  const {
    data: accountIdentificationData,
    isSuccess: isAccountIdentificationSuccess,
    remove: removeAccountIdentificationDataFromCache,
  } = useAccountIdentification({
    familyName: surname,
    scopedToken,
  });
  const { data: userRegistrationStatus, isSuccess: userRegistrationSuccess } =
    useUserRegistrationStatus({
      scopedToken:
        userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails &&
        isAccountIdentificationSuccess
          ? scopedToken
          : '',
    });

  const handleSubmit = (values: YourAccountFormValues) => {
    // Clear blackhole data from cache to stop the blackhole email account user query. This means the cache is empty and the query will be made again with the new values.
    removeAccountIdentificationDataFromCache();

    const { accountNumber, postcode, surname } = values;

    // Set account field values in context
    setAccountFields({ accountNumber, postcode, surname });

    // Resets the scopedToken for a fresh search
    setScopedToken('');

    // mutation for authentication
    mutate({ input: { accountNumber, postcode, familyName: surname } });
  };

  // Display an alert if the user has already registered an email, but only on the registration journey
  const displayEmailInUseAlert =
    userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails &&
    !!userRegistrationStatus?.email;

  const saveBlackholeAccountDataToContext = useCallback(() => {
    // Save whatever security info we have for this user to context, so we can use it on later pages. Not only do we need some of this on the security page for cases where only one user is returned, but this means we don't need to call the blackhole email account API again.
    setHasDateOfBirth(
      accountIdentificationData.blackholeEmailAccountUser.hasDateOfBirth
    );
    setHasMobileNumber(
      accountIdentificationData.blackholeEmailAccountUser.hasMobileNumber
    );
    setBlackholeAccountUsers(
      accountIdentificationData.blackholeEmailAccountUser.accounts[0]
        .accountUserNames
    );
  }, [
    accountIdentificationData,
    setHasDateOfBirth,
    setHasMobileNumber,
    setBlackholeAccountUsers,
  ]);

  useEffect(() => {
    // This is in case the user goes back to this page and changes the surname input, we need to clear the selected account user name for the next step
    setSelectedAccountUserName({ givenName: '', familyName: '' });
    setSelectedUserId('');
  }, []);

  useEffect(() => {
    // Proceed to the next step if the verifyIdentity mutation is successful, and the final query is also successful too. On the forgotten email journey, that's the account identification query and on the register journey, that's the user registration status query.
    if (
      isVerifyIdentitySuccess &&
      (userRegistrationJourneyConfig[journeyKey].blockForRegisteredEmails
        ? userRegistrationSuccess
        : isAccountIdentificationSuccess)
    ) {
      if (accountIdentificationData) {
        const notEnoughInfoToProceed =
          !accountIdentificationData.blackholeEmailAccountUser.hasDateOfBirth &&
          !accountIdentificationData.blackholeEmailAccountUser.hasMobileNumber;

        // Redirect to next step of the journey
        if (
          accountIdentificationData.blackholeEmailAccountUser.accounts[0]
            .accountUserNames.length === 1
        ) {
          // If the user has already registered an email, or if the user doesn't have either a DOB or mobile number, don't do a redirect
          if (displayEmailInUseAlert || notEnoughInfoToProceed) {
            return;
          }

          // Save the blackhole account data to context, now that we know we're able to proceed
          saveBlackholeAccountDataToContext();

          const accountUser =
            accountIdentificationData.blackholeEmailAccountUser.accounts[0]
              .accountUserNames[0];

          // If there is only one matching user, go straight to the security page, after saving this user's details for later use
          setSelectedAccountUserName({
            givenName: accountUser.givenName,
            familyName: accountUser.familyName,
          });
          setSelectedUserId(accountUser.id);

          // Go straight to security page, since we don't need to select a user
          push(INTERNAL_PATHS[`${journeyKey}_SECURITY`].path);
        } else {
          // Save the blackhole account data to context
          saveBlackholeAccountDataToContext();

          // If there are multiple matching users, go to the about you page to select the correct user
          push(INTERNAL_PATHS[`${journeyKey}_ABOUT_YOU`].path);
        }
      }
    }
  }, [
    isVerifyIdentitySuccess,
    setSelectedAccountUserName,
    push,
    isAccountIdentificationSuccess,
    accountIdentificationData,
    displayEmailInUseAlert,
    saveBlackholeAccountDataToContext,
    journeyKey,
    setSelectedUserId,
    userRegistrationSuccess,
  ]);

  return (
    <YourAccountForm
      accountNumber={accountNumber}
      postcode={postcode}
      surname={surname}
      onSubmit={(values) => handleSubmit(values)}
      showGenericError={isVerifyIdentityError}
      journeyKey={journeyKey}
    />
  );
};

export default AccountFinder;
