import { Grid } from '@krakentech/coral';
import { IconGBP } from '@krakentech/icons';

import { ActionCard } from '@/components';
import { useCurrentActivePaymentSchedule } from '@/hooks/billsAndPayments/useCurrentActivePaymentSchedule';
import { INTERNAL_PATHS } from '@/utils/urls';

type ManagePaymentsCardProps = {
  isLoading: boolean;
  isError: boolean;
};

const ManagePaymentsCard = ({
  isLoading,
  isError,
}: ManagePaymentsCardProps) => {
  const {
    data: paymentSchedule,
    isLoading: currentActivePaymentScheduleLoading,
    isError: currentActivePaymentScheduleError,
  } = useCurrentActivePaymentSchedule();

  const combinedIsLoading = isLoading || currentActivePaymentScheduleLoading;
  const combinedIsError = isError || currentActivePaymentScheduleError;

  const href = INTERNAL_PATHS.MANAGE_PAYMENTS.path;

  const hasNoDDPaymentScheduleType =
    paymentSchedule?.node?.scheduleType !== 'DIRECT_DEBIT';

  if (hasNoDDPaymentScheduleType) {
    return null;
  }

  return (
    <Grid.Item>
      <ActionCard
        href={href}
        title={'Manage payments'}
        titleComponent="p"
        icon={<IconGBP size={32} />}
        isLoading={combinedIsLoading}
        isError={combinedIsError}
      />
    </Grid.Item>
  );
};

export default ManagePaymentsCard;
