import { Typography } from '@krakentech/coral';

import { piiDataAttributes } from '../PII/constants';

import { StyledMutedTextContainer } from './index.styled';
import { MutedTextProps } from './index.types';

const MutedText = ({
  textAlign = 'left',
  textWrap = 'wrap',
  variant = 'body2',
  containsPII = false,
  attributes,
  children,
}: MutedTextProps) => (
  <StyledMutedTextContainer>
    <Typography
      color="inherit"
      variant={variant}
      textAlign={textAlign}
      textWrap={textWrap}
      attributes={
        containsPII ? { ...attributes, ...piiDataAttributes } : attributes
      }
    >
      {children}
    </Typography>
  </StyledMutedTextContainer>
);

export default MutedText;
