import { RefAttributes } from 'react';
import {
  FormikTextField,
  FormikTextFieldProps,
} from '@krakentech/coral-formik';

import { piiDataAttributes } from '../constants';

const PIIFormikTextField = (
  props: FormikTextFieldProps & RefAttributes<HTMLInputElement>
) => (
  <FormikTextField
    {...props}
    attributes={{
      ...props.attributes,
      ...piiDataAttributes,
    }}
  />
);

export default PIIFormikTextField;
