import { Card, Stack, Typography } from '@krakentech/coral';

import { MutedText } from '@/components';
import UpcomingPaymentSchedule from '@/components/billingAndPayment/UpcomingPaymentSchedule';
import { useSyncFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';

import PIITypography from '../PII/PIITypography';

import { PaymentInfoCardProps } from './index.types';

const PaymentInfoCard = ({
  header,
  amount,
  explanation,
  dataAttributes,
  isNextMonthlyPaymentCard,
}: PaymentInfoCardProps) => {
  const showPaymentSchedule = useSyncFeatureFlag(
    FeatureNames.ChangePaymentSchedule
  );

  return (
    <Card
      {...(dataAttributes && { dataAttributes })}
      fullHeight={isNextMonthlyPaymentCard}
    >
      <Card.Body>
        <Stack direction="vertical" gap="smMd" alignItems="center">
          <Typography component="h2" variant="h2">
            {header}
          </Typography>

          <Stack direction="vertical" gap="xsSm" alignItems="center">
            <PIITypography component="div" variant="homepageTitle">
              {amount}
            </PIITypography>

            {explanation && (
              <MutedText textAlign="center" containsPII>
                {explanation}
              </MutedText>
            )}
            {isNextMonthlyPaymentCard && showPaymentSchedule && (
              <UpcomingPaymentSchedule />
            )}
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default PaymentInfoCard;
