import { Stack, Typography, Link } from '@krakentech/coral';

import { Alert, UnorderedList } from '@/components';
import { INTERNAL_PATHS } from '@/utils/urls';

const EmailAlreadyRegisteredAlert = ({ email }: { email: string }) => (
  <Alert severity="warning">
    <Stack gap="sm" direction="vertical">
      <Typography>
        This account is already registered under {email}. What would you like to
        do:
      </Typography>
      <UnorderedList
        gap="xs"
        listItems={[
          <Link href={INTERNAL_PATHS.LOGIN.path} key="Log in">
            <span>Log in</span>
          </Link>,
          <Link
            href={INTERNAL_PATHS.FORGOTTEN_PASSWORD.path}
            key="Reset password"
          >
            <span>Reset password</span>
          </Link>,
        ]}
      />
    </Stack>
  </Alert>
);

export default EmailAlreadyRegisteredAlert;
