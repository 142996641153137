import { Stack } from '@krakentech/coral';
import { FormikTextFieldProps } from '@krakentech/coral-formik';

import MutedText from '@/components/MutedText';
import PIIFormikTextField from '@/components/PII/PIIFormikTextField';

type FormTextFieldWithDescriptionProps = {
  description: string;
};

const FormTextFieldWithDescription = ({
  id,
  label,
  name,
  description,
  onChange,
  disabled,
}: FormikTextFieldProps & FormTextFieldWithDescriptionProps) => {
  return (
    <Stack direction="vertical" gap="xs">
      <PIIFormikTextField
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
      <MutedText variant="caption">{description}</MutedText>
    </Stack>
  );
};

export default FormTextFieldWithDescription;
