import {
  CoralConfigOptions,
  CoralColors,
  octopusTheme,
} from '@krakentech/coral';
import { rgba } from 'polished';

import { kfuTypography } from '@/styles/typography';
import { convertHexToRgbaWithOpacity } from '@/utils/styles';

import {
  baseColors,
  white,
  alertBackgroundColors,
  black,
  textColors,
  datePickerStyles,
  stepLineBorderWidth,
  stepLabelColor,
  buttonFocusOutline,
  ExtendedCoralStyleProperties,
  defaultLinkComponent,
} from './common';

const stwColors = {
  primary: {
    main: '#003E52',
    main10: 'rgba(0, 62, 82, 0.1)',
    light: '#95ACB5',
    dark: '#00CAAF',
    intense: '#003E52',
  },
  secondary: {
    main: '#003E52',
    main10: white,
    light: white,
    dark: '#00CAAF',
  },
  tertiary: {
    main: '#003E52',
    light: '#95ACB5',
    light10: 'rgba(0, 62, 82, 0.1)',
  },
  surface: {
    main: white,
    dark: white,
    mid: white,
    light: '#E9E9EC',
  },
  base: baseColors,
  text: {
    main: '#1E0D35',
    dark: '#696176',
    mid: '#A3A0AC',
    mid20: 'rgba(163, 160, 172, 0.2)',
    light: '#1E0D35',
  },
  action: {
    info: '#0B85D7',
    info10: 'rgba(11, 133, 215, 0.1)',
    info30: 'rgba(11, 133, 215, 0.3)',
    infoLight: '#D8EEFD',
    success: '#10845E',
    success10: 'rgba(16, 132, 94, 0.1)',
    success30: 'rgba(16, 132, 94, 0.3)',
    successLight: '#D2F9EC',
    warning: '#FFCE04',
    warning10: 'rgba(255, 206, 4, 0.1)',
    warning30: 'rgba(255, 206, 4, 0.3)',
    warningLight: '#FFFAE5',
    error: '#E90125',
    error10: 'rgba(233, 1, 37, 0.1)',
    error30: 'rgba(233, 1, 37, 0.3)',
    errorLight: '#FFE6EA',
  },
};

const colors: CoralColors = {
  ...stwColors,
};

const borderWidth = '0.1rem';
const borderRadius = '0.4rem';
const cardBorderWidth = '0.2rem';

const buttonBorderRadius = '1rem';
const cardBorderRadius = '1.2rem';

const buttonBoxShadow = `0 0 1rem 0 rgba(0, 0, 0, 0.1)`;
const cardBoxShadow = `0 ${cardBorderWidth} 0.6rem 0 ${rgba(black, 0.1)}`;

const theme: Partial<CoralConfigOptions> = {
  LinkComponent: defaultLinkComponent,
  overrides: {
    animation: {
      easing: 'ease-out',
      durationMs: 250,
    },
    borderWidth,
    borderRadius,
    color: colors,
    components: {
      alert: {
        root: {
          padding: `${octopusTheme.spacing.xsSm} ${octopusTheme.spacing.smMd}`,
          border: 'none',
          borderRadius: cardBorderRadius,
          color: stwColors.text.main,
        },
        ...alertBackgroundColors,
      },
      banner: {
        root: {
          background: stwColors.action.successLight,
        },
      },
      button: {
        root: {
          borderRadius: buttonBorderRadius,
        },
        primary: {
          contained: {
            color: `${white}!important`,
            '& svg path': {
              fill: white,
            },
            ':hover': {
              backgroundColor: stwColors.primary.dark,
              color: `${stwColors.text.main}!important`,
              borderColor: stwColors.primary.dark,
              '& svg path': {
                fill: stwColors.text.main,
              },
            },
            ':disabled': {
              backgroundColor: stwColors.primary.light,
              borderColor: stwColors.primary.light,
              color: `${white}!important`,
            },
            ':focus': {
              ...buttonFocusOutline(stwColors.primary.dark),
            },
          } as ExtendedCoralStyleProperties,
          outlined: {
            ':hover': {
              color: `${white}!important`,
              background: stwColors.primary.main,
            },
            ':focus': {
              color: `${white}!important`,
              background: stwColors.primary.main,
              borderColor: stwColors.primary.dark,
              ...buttonFocusOutline(stwColors.primary.dark),
            },
          },
          text: {
            ':hover': {
              color: stwColors.primary.dark,
            },
          },
          link: {
            padding: '0',
          },
        },
        secondary: {
          contained: {
            background: white,
            color: stwColors.primary.main,
            borderColor: 'transparent',
            boxShadow: buttonBoxShadow,
            ':hover': {
              backgroundColor: stwColors.primary.dark,
              boxShadow: 'none',
            },
            ':focus': {
              borderColor: stwColors.primary.dark,
              boxShadow: 'none',
              ...buttonFocusOutline(stwColors.primary.dark),
            },
          },
          outlined: {
            ':focus': {
              ...buttonFocusOutline(stwColors.primary.dark),
            },
          },
          ghost: {
            color: stwColors.text.main,
            ':hover': {
              color: stwColors.text.main,
            },
            ':focus': {
              color: stwColors.text.main,
              borderColor: stwColors.primary.dark,
            },
          },
        },
        tertiary: {
          contained: {
            backgroundColor: white,
            color: stwColors.primary.main,
            borderColor: white,
            ':hover': {
              backgroundColor: stwColors.primary.main10,
              borderColor: 'transparent',
              '& svg path': {
                fill: stwColors.text.dark,
              },
            },
            ':focus': {
              backgroundColor: stwColors.primary.main10,
              borderColor: stwColors.primary.main,
              '& svg path': {
                fill: stwColors.text.dark,
              },
            },
            ':disabled': {
              backgroundColor: stwColors.text.mid20,
              borderColor: stwColors.text.mid20,
            },
          } as ExtendedCoralStyleProperties,
          ghost: {
            color: stwColors.text.main,
            '& svg path': {
              fill: stwColors.text.main,
            },
            ':hover': {
              color: stwColors.primary.main,
            },
            ':focus': {
              borderColor: stwColors.primary.dark,
            },
          } as ExtendedCoralStyleProperties,
        },
      },
      card: {
        root: {
          boxShadow: cardBoxShadow,
          color: stwColors.text.main,
          borderRadius: cardBorderRadius,
          borderWidth: cardBorderWidth,
        },
        light: {
          backgroundColor: baseColors.dark,
        },
      },
      checkbox: {
        root: {
          span: {
            fontSize: `${octopusTheme.typography.body1.baseStyles.fontSize}`,
          },
          'input:checked + div': {
            backgroundColor: colors.primary.main,
          },
          'input + div': {
            '&:after': {
              backgroundColor: convertHexToRgbaWithOpacity(
                colors.primary.main,
                0.3
              ),
            },
          },
          'svg path': {
            fill: white,
          },
        } as ExtendedCoralStyleProperties,
        indicator: {
          border: `0.2rem solid ${colors.primary.main}`,
        },
      },
      datepicker: {
        calendar: {
          ...datePickerStyles,
          '.rdp-caption': {
            backgroundColor: stwColors.primary.main,
          },
          '.rdp-year_month-button:hover': {
            backgroundColor: stwColors.tertiary.main,
            color: white,
          },
          '.rdp-caption_label span, .rdp-nav button, .rdp-caption button': {
            color: white,
          },
          '.rdp-month, .rdp-month-only, .rdp-year-only': {
            backgroundColor: white,
          },
          '.rdp-day_selected, .rdp-year_month-button--selected': {
            color: stwColors.primary.main,
            borderColor: stwColors.tertiary.main,
          },
          '.rdp-button.rdp-day:hover:not([disabled]):not(.rdp-day_selected), .rdp-day:focus-visible:not([disabled]), .rdp-day_selected:hover, .rdp-year_month-button:focus-visible':
            {
              backgroundColor: stwColors.tertiary.main,
              color: textColors.light,
              outlineColor: stwColors.tertiary.main,
              border: 'none',
            },
        },
      },
      dialog: {
        scrollWrapper: {
          border: `${borderWidth} solid ${stwColors.text.mid}`,
        },
        content: {
          '> div': {
            '> div': {
              // removes bottom margin from close cross
              marginBottom: 0,
              'svg path': {
                fill: stwColors.primary.main,
              },
            },
          },
        } as ExtendedCoralStyleProperties,
      },
      error: {
        root: {
          background: white,
          borderRadius: cardBorderRadius,
          boxShadow: cardBoxShadow,
        },
      },
      loader: {
        linear: {
          root: {
            backgroundColor: white,
          },
        },
      },
      stepper: {
        stepGroup: {
          ...stepLabelColor,
        } as ExtendedCoralStyleProperties,
        stepDisc: {
          root: {
            background: textColors.mid20,
            color: stwColors.text.mid,
          },
          isCurrent: {
            background: stwColors.primary.main,
            borderColor: stwColors.primary.main,
            color: textColors.light,
          },
          isComplete: {
            background: stwColors.primary.dark,
            borderColor: stwColors.primary.dark,
          },
        },
        stepLine: {
          root: {
            borderWidth: stepLineBorderWidth,
            borderColor: stwColors.text.mid,
          },
          isCurrent: {
            borderColor: stwColors.primary.dark,
          },
          isComplete: {
            borderColor: stwColors.primary.dark,
          },
        },
      },
      switch: {
        root: {
          'input[disabled] ~ span[data-testid="switch__handle"]': {
            color: `${stwColors.primary.light} !important`,
          },
          'input[disabled] ~ span[data-testid="switch__track"]': {
            background: `${stwColors.primary.light} !important`,
          },
        } as ExtendedCoralStyleProperties,
        track: {
          background: `${stwColors.surface.light} !important`,
        },
        handle: {
          color: `${stwColors.primary.main} !important`,
          zIndex: 0,
        },
        checked: {
          track: {
            background: `${stwColors.primary.light} !important`,
          },
        },
      },
      toast: {
        root: {
          color: textColors.main,
        },
      },
      tooltip: {
        tooltipContent: {
          background: stwColors.primary.main,
          fill: stwColors.primary.main,
          color: white,
        },
        button: {
          '& svg path': {
            fill: colors.primary.main,
          },
          p: {
            color: colors.primary.main,
          },
        } as ExtendedCoralStyleProperties,
      },
      typography: {
        link: {
          color: stwColors.primary.main,
          ':hover': {
            color: stwColors.primary.dark,
          },
        },
      },
    },
    name: 'Severn Trent Theme',
    spacing: {
      ...octopusTheme.spacing,
    },
    breakpoints: {
      ...octopusTheme.breakpoints,
      md: 767,
    },
    typography: {
      ...octopusTheme.typography,
      ...kfuTypography,
      fontFamily: 'Work Sans, Arial, helvetica, arial, sans-serif',
    },
  },
  defaultProps: {
    card: {
      theme: 'light',
      padding: 'medium',
      borderless: true,
    },
    blockquote: {
      gap: 'lg',
    },
    button: {
      size: 'small',
    },
    loader: {
      color: 'primary',
    },
    radio: {
      theme: 'light',
    },
    skeleton: {
      animation: 'pulse',
    },
    // Theme light is required because using dark causes the border to become too light on hover
    textfield: {
      theme: 'light',
    },
    tooltip: {
      theme: 'light',
    },
    typography: {
      color: 'inherit',
    },
  },
};

export default theme;
