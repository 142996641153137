// A text field with a £ sign as a prefix
import { Typography } from '@krakentech/coral';

import PIIFormikTextField from '../PII/PIIFormikTextField';

import { StyledPriceFieldContainer, StyledPrefixWrapper } from './index.styled';
import { PriceTextFieldProps } from './index.types';

const PriceTextField = ({
  name,
  label,
  placeholder,
  onBlur,
}: PriceTextFieldProps) => (
  <StyledPriceFieldContainer>
    <PIIFormikTextField
      name={name}
      type="number"
      label={label}
      placeholder={placeholder}
      startIcon={
        <StyledPrefixWrapper>
          <Typography>£</Typography>
        </StyledPrefixWrapper>
      }
      onBlur={onBlur}
    />
  </StyledPriceFieldContainer>
);

export default PriceTextField;
