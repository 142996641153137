import { Stack, Radio } from '@krakentech/coral';
import { useFormikContext } from 'formik';

import PIIFormikTextField from '@/components/PII/PIIFormikTextField';
import PIITypography from '@/components/PII/PIITypography';
import { RadioOverride } from '@/styles/Overrides.styled';
import { BillingAddressDetails } from '@/types/billingAddress';

const BillingAddressSection = ({
  supplyAddress,
  supplyAddressPostcode,
  hasMultipleSupplyAddresses,
}: {
  supplyAddress: string[];
  supplyAddressPostcode: string;
  hasMultipleSupplyAddresses: boolean;
}) => {
  const { values, setFieldValue, initialValues } =
    useFormikContext<BillingAddressDetails>();

  const setSupplyAddressAsBillingAddress = () => {
    setFieldValue('billingAddressLine1', supplyAddress[0]);
    setFieldValue('billingAddressLine2', supplyAddress[1]);
    setFieldValue('billingAddressLine3', supplyAddress[2]);
    setFieldValue('billingAddressLine4', supplyAddress[3]);
    setFieldValue('billingAddressLine5', supplyAddress[4]);
    setFieldValue('billingAddressPostcode', supplyAddressPostcode);
  };

  const clearBillingAddressFields = () => {
    setFieldValue('billingAddressLine1', '');
    setFieldValue('billingAddressLine2', '');
    setFieldValue('billingAddressLine3', '');
    setFieldValue('billingAddressLine4', '');
    setFieldValue('billingAddressLine5', '');
    setFieldValue('billingAddressPostcode', '');
  };

  const getSupplyAddressString = () => {
    const supplyAddressWithoutBlanks = supplyAddress?.filter(Boolean);

    return `${supplyAddressWithoutBlanks?.join(
      ', '
    )}, ${supplyAddressPostcode}`;
  };

  return (
    <Stack direction="vertical" gap="md">
      {!hasMultipleSupplyAddresses && supplyAddress && (
        <RadioOverride>
          <Radio
            name="billingAddressSameAsSupplyAddress"
            onChange={(e) => {
              const value = e.target.value;

              // If the user has selected the supply address radio, then set the billing address fields to the supply address fields
              if (value === 'true') {
                setSupplyAddressAsBillingAddress();
              } else {
                // If the user has selected "A different address" but their current billing address on Kraken is their supply address, then clear the billing address fields
                if (initialValues.billingAddressSameAsSupplyAddress === true) {
                  clearBillingAddressFields();
                }
              }

              // Set the Formik field value
              setFieldValue('billingAddressSameAsSupplyAddress', value);
            }}
            defaultValue={
              initialValues.billingAddressSameAsSupplyAddress === true
                ? 'true'
                : 'false'
            }
          >
            <Radio.Option value="true">
              <PIITypography>{getSupplyAddressString()}</PIITypography>
            </Radio.Option>
            <Radio.Option value="false">A different address</Radio.Option>
          </Radio>
        </RadioOverride>
      )}

      {(hasMultipleSupplyAddresses ||
        values.billingAddressSameAsSupplyAddress === false ||
        values.billingAddressSameAsSupplyAddress === 'false' ||
        !supplyAddress) && (
        <>
          <PIIFormikTextField
            name="billingAddressLine1"
            label="Address line 1 *"
          />
          <PIIFormikTextField
            name="billingAddressLine2"
            label="Address line 2"
          />
          <PIIFormikTextField
            name="billingAddressLine3"
            label="Address line 3"
          />
          <PIIFormikTextField
            name="billingAddressLine4"
            label="Address line 4"
          />
          <PIIFormikTextField
            name="billingAddressLine5"
            label="Address line 5"
          />
          <PIIFormikTextField
            name="billingAddressPostcode"
            label="Postcode *"
          />
        </>
      )}
    </Stack>
  );
};

export default BillingAddressSection;
