import { FC, ReactNode } from 'react';
import NextImage from 'next/image';
import Link from 'next/link';
import { Grid, Stack, useCoralBreakpoints } from '@krakentech/coral';

import { StyledMobileTabLink } from './index.styled';

const MobileNavLink: FC<{
  href: string;
  label: string;
  isActive: boolean;
  ActiveIcon: ReactNode;
  inactiveIcon: string;
  iconWidth?: number;
  iconHeight?: number;
}> = ({
  href,
  label,
  isActive,
  ActiveIcon,
  inactiveIcon,
  iconWidth = 24,
  iconHeight = 21,
}) => {
  const { isMinXs } = useCoralBreakpoints();

  return (
    <Grid.Item>
      <Link href={href} passHref legacyBehavior>
        <StyledMobileTabLink active={isActive} aria-label={label}>
          <Stack
            direction="vertical"
            alignItems="center"
            justifyContent="flex-start"
            gap="xs"
          >
            {isActive ? (
              ActiveIcon
            ) : (
              <NextImage
                src={inactiveIcon}
                alt=""
                width={iconWidth}
                height={iconHeight}
              />
            )}
            {isMinXs && <span>{label}</span>}
          </Stack>
        </StyledMobileTabLink>
      </Link>
    </Grid.Item>
  );
};

export default MobileNavLink;
