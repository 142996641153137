import { useState } from 'react';
import { IconCircleUser } from '@krakentech/icons';
import { getCookie, setCookie } from 'cookies-next';

import EnrichmentCard from '@/components/EnrichmentCard';
import { usePersonalDetails } from '@/hooks/accounts/usePersonalDetails';
import { COOKIES } from '@/utils/cookies';
import { INTERNAL_PATHS } from '@/utils/urls';
import { isFieldEmpty } from '@/utils/validators/general';

const AccountEnrichmentCard = () => {
  const [displayComponent, setDisplayComponent] = useState(true);

  const { data, isLoading, isError } = usePersonalDetails();

  if (isLoading || isError) return null;

  const { viewer } = data;

  const isGivenNameEmpty = isFieldEmpty(viewer.givenName);
  const isFamilyNameEmpty = isFieldEmpty(viewer.familyName);
  const isGivenNameASingleCharacter = viewer.givenName.length === 1;

  const isDateOfBirthEmpty = isFieldEmpty(viewer.dateOfBirth);
  const isContactNumberEmpty =
    isFieldEmpty(viewer.mobile) && isFieldEmpty(viewer.landline);

  if (
    !isGivenNameEmpty &&
    !isFamilyNameEmpty &&
    !isGivenNameASingleCharacter &&
    !isDateOfBirthEmpty &&
    !isContactNumberEmpty
  ) {
    return null;
  }

  const missingFields = [];

  const missingNameFields = [
    {
      condition:
        (isGivenNameEmpty || isGivenNameASingleCharacter) && isFamilyNameEmpty,
      message: 'full first and last name',
    },
    {
      condition: isGivenNameEmpty,
      message: 'first name',
    },
    {
      condition: isGivenNameASingleCharacter,
      message: 'full first name',
    },
    {
      condition: isFamilyNameEmpty,
      message: 'last name',
    },
  ];

  // Add a different missing name field depending on what part of or if all of the name is missing
  for (const { condition, message } of missingNameFields) {
    if (condition) {
      missingFields.push(message);
      break;
    }
  }

  if (isDateOfBirthEmpty) {
    missingFields.push('date of birth');
  }

  if (isContactNumberEmpty) {
    missingFields.push('contact number');
  }

  const formatMissingFields = (input: string[]) => {
    const numberOfFields = input.length;

    if (numberOfFields === 0) {
      return '';
    } else if (numberOfFields === 1) {
      return input[0];
    } else if (numberOfFields === 2) {
      return input.join(' & ');
    } else {
      const firstPart = input.slice(0, numberOfFields - 2).join(', ');
      const lastPart = input.slice(numberOfFields - 2).join(' & ');
      return `${firstPart}, ${lastPart}`;
    }
  };

  const missingFieldsText = formatMissingFields(missingFields);

  const { SNOOZE_ACCOUNT_ENRICHMENT } = COOKIES;

  const handleClose = () => {
    setCookie(SNOOZE_ACCOUNT_ENRICHMENT, true);
    setDisplayComponent(false);
  };

  if (!displayComponent || getCookie(SNOOZE_ACCOUNT_ENRICHMENT)) return null;

  const personalDetailsHref = INTERNAL_PATHS.UPDATE_PERSONAL_DETAILS.path;

  return (
    <EnrichmentCard
      title="Let's complete your profile!"
      subtitle={`Add your ${missingFieldsText}`}
      icon={<IconCircleUser />}
      closeCard={handleClose}
      href={personalDetailsHref}
    />
  );
};

export default AccountEnrichmentCard;
