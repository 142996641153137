import {
  Stack,
  Tooltip,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';

import PIIStack from '@/components/PII/PIIStack';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';
import { useFullNameAndSupplyAddress } from '@/hooks/accounts/useFullNameAndSupplyAddress';
import { useIsOnMeteredTariff } from '@/hooks/usage/useIsOnMeteredTariff';

import { Body1Skeleton, H1Skeleton } from '../../Skeletons';

import { StyledTooltipToggle } from './index.styled';

const DashboardGreeting = () => {
  const { isMaxMd } = useCoralBreakpoints();

  const { accountNumber } = useAccountNumberContext();

  const {
    data: fullNameAndSupplyAddress,
    isError: fullNameSupplyAddressError,
  } = useFullNameAndSupplyAddress();
  const { data: isMeasured, isError: isMeasuredError } = useIsOnMeteredTariff();

  if (fullNameAndSupplyAddress) {
    const {
      viewer: { title, fullName },
      account: { properties },
    } = fullNameAndSupplyAddress;

    const hasMovedOut = !properties.length;

    return (
      <PIIStack direction="vertical" gap="xxs" md={{ gap: 'xs' }}>
        <Typography variant="h1">
          Hello {title} {fullName}
        </Typography>

        <Stack
          gap="xxs"
          direction="vertical"
          alignItems="flex-start"
          md={{
            direction: 'horizontal',
            gap: 'sm',
          }}
        >
          {/* TODO: handle those with multiple addresses (not necessary for this cohort) */}
          {properties[0]?.address && (
            <>
              <Typography>{properties[0]?.address}</Typography>
              {!isMaxMd && <Typography>|</Typography>}
            </>
          )}

          <Stack gap="xs" md={{ gap: 'sm' }}>
            <Tooltip title="Your account number" theme="dark" zIndex={1}>
              <StyledTooltipToggle>
                <Typography>{accountNumber}</Typography>
              </StyledTooltipToggle>
            </Tooltip>
            {!hasMovedOut && typeof isMeasured === 'boolean' && (
              <>
                <Typography>|</Typography>
                <Tooltip
                  theme="dark"
                  title={
                    isMeasured
                      ? 'Your property has a meter installed which tracks your usage'
                      : 'Your property does not currently have a meter installed'
                  }
                  zIndex={1}
                >
                  <Typography>
                    <StyledTooltipToggle>
                      {isMeasured ? 'Metered' : 'Unmetered'}
                    </StyledTooltipToggle>
                  </Typography>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
      </PIIStack>
    );
  }

  if (fullNameSupplyAddressError || isMeasuredError) {
    return null;
  }

  return (
    <Stack direction="vertical" gap="sm">
      <H1Skeleton />
      <Body1Skeleton width={isMaxMd ? undefined : 750} />
    </Stack>
  );
};

export default DashboardGreeting;
