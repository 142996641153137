import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import { useAccountNumberContext } from '@/context/AccountNumberContext/withFetch/AccountNumberContext';

import { isOnMeteredTariffSchema } from '../validations';

import IsOnMeteredTariff from './IsOnMeteredTariff.graphql';

export const useIsOnMeteredTariff = () => {
  const { accountNumber } = useAccountNumberContext();

  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['isOnMeteredTariff'],
    query: IsOnMeteredTariff,
    validateFn(response) {
      return isOnMeteredTariffSchema.validate(response);
    },
    select: (data: any) => data.account.isMeasured,
    enabled: Boolean(isAuthenticated && accountNumber),
    variables: { accountNumber },
    cacheTime: 0,
  });
};
