import { useEffect } from 'react';

import ManagePaymentsCard from '@/components/overview/ManagePaymentsCard';
import SetupDirectDebitCard from '@/components/overview/SetupDirectDebitCard';
import { useHasCurrentDirectDebitInstruction } from '@/hooks/billsAndPayments/useHasCurrentDirectDebitInstruction';

const DirectDebitActionCard = () => {
  const {
    isLoading: isDirectDebitInstructionLoading,
    isError: isDirectDebitInstructionError,
    data: hasDirectDebitInstruction,
    refetch: refetchDirectDebitInstruction,
    isRefetching: isRefetchingDirectDebitInstruction,
    isRefetchError: isRefetchingDirectDebitInstructionError,
  } = useHasCurrentDirectDebitInstruction();

  useEffect(() => {
    refetchDirectDebitInstruction();
  }, [refetchDirectDebitInstruction]);

  const isLoading =
    isDirectDebitInstructionLoading || isRefetchingDirectDebitInstruction;

  const isError =
    isDirectDebitInstructionError || isRefetchingDirectDebitInstructionError;

  if (hasDirectDebitInstruction) {
    return <ManagePaymentsCard isLoading={isLoading} isError={isError} />;
  }

  return <SetupDirectDebitCard isLoading={isLoading} isError={isError} />;
};

export default DirectDebitActionCard;
