import { Button } from '@krakentech/coral';
import styled, { css } from 'styled-components';

export const StyledEllipsisButtonContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.color.text.mid20};
    }
  `}
`;

// @ts-expect-error - this TS error has popped up since the Next 13 upgrade, and shouldn't be happning because Button is a styled component. Would need to investigate further.
export const StyledEllipsisButton = styled(Button)`
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const Panel = styled.div`
  ${({ theme }) => css`
    background-color: white;
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    border: 1px solid ${theme.color.secondary.main10};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    z-index: 1000;
    padding: 8px 16px;
  `}
`;

// @ts-expect-error - this TS error has popped up since the Next 13 upgrade, and shouldn't be happning because Button is a styled component. Would need to investigate further.
export const StyledButton = styled(Button)`
  ${({ theme }) => css`

  &:focus {
    text-decoration: none;
  }

    &:hover {
      background-color: white;

      h3 {
        color: ${theme.color.primary.dark};
      }

    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
      -web
    }
  `}
`;
