import { useEffect, useRef } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

import { LogoutType } from '@/utils/broadcastLogout';
import { captureSentryMessage } from '@/utils/sentry';

import { useAuth } from './index';

const logoutMessages = {
  RESET_PASSWORD: 'User logged out due to password reset',
  UNAUTHORIZED_SESSION: 'Unauthorized session detected. Logging out...',
};

const LogoutListener = () => {
  const { logout } = useAuth();
  const logoutChannelRef = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    if (!logoutChannelRef.current) {
      logoutChannelRef.current = new BroadcastChannel('logout');

      logoutChannelRef.current.onmessage = (event) => {
        // Ensure the received event has valid data
        const eventData = event?.data ?? event;

        if (!eventData || typeof eventData !== 'object') {
          return;
        }

        const { type } = eventData;

        // Log out the user if the received event is a logout event
        const message = logoutMessages[type as LogoutType];
        if (message) {
          console.warn(message);
          // Fire Sentry event to track logouts via broadcast channel
          captureSentryMessage(message, 'info');
          logout();
        }
      };
    }
  }, [logout]);

  return null;
};

export default LogoutListener;
