import {
  captureMessage,
  captureException,
  SeverityLevel,
} from '@sentry/browser';
import { init } from '@sentry/nextjs';

import { getEnvironment } from './environment';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// This function configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
export const initSentry = () => {
  init({
    dsn: SENTRY_DSN,

    environment: getEnvironment(),

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
  });
};

// Capture an exception and send it to Sentry
export const captureSentryException = (error: Error): string => {
  return captureException(error);
};

// Capture a message and send it to Sentry
export const captureSentryMessage = (
  message: string,
  level: SeverityLevel = 'info'
): string => {
  return captureMessage(message, level);
};
