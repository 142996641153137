import { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';

import PIIFormikTextField from '@/components/PII/PIIFormikTextField';
import { makeMeterReadingCorrectLength } from '@/utils/formatters/valueCubicMetres';

import { MeterReading } from '../MeterReadingsList/index.types';

import { StyledMeterReadingFieldContainer } from './index.styled';

export const DEFAULT_LAST_READING = {
  valueCubicMetres: '0',
  readingDate: new Date(),
};

const MeterReadingField = ({
  numberOfDigits,
  placeholder,
  lastReading,
}: {
  numberOfDigits: number;
  placeholder: string;
  lastReading?: string;
}) => {
  const {
    values: { valueCubicMetres },
    setFieldValue,
  } = useFormikContext<MeterReading>();

  const autofillZeros = () => {
    if (valueCubicMetres) {
      setFieldValue(
        'valueCubicMetres',
        makeMeterReadingCorrectLength(valueCubicMetres, numberOfDigits)
      );
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      // Turn value into positive number and remove any decimal places before saving to formik context
      const positiveWholeNumber = Math.abs(parseInt(e.target.value));

      setFieldValue('valueCubicMetres', positiveWholeNumber);
    }
  };

  return (
    // If the user hasn't entered anything in the field yet, and if they don't have a previous meter reading, show the input text (inc. placeholder) in the normal font size
    <StyledMeterReadingFieldContainer
      normalSizedInputText={!lastReading && !valueCubicMetres}
    >
      <PIIFormikTextField
        name="valueCubicMetres"
        label=""
        placeholder={placeholder}
        type="number"
        onBlur={autofillZeros}
        onChange={handleOnChange}
      />
    </StyledMeterReadingFieldContainer>
  );
};

export default MeterReadingField;
