import { PropsWithChildren } from 'react';
import { Stack, StackProps } from '@krakentech/coral';

import { piiDataAttributes } from '../constants';

const PIIStack = ({ children, ...props }: StackProps & PropsWithChildren) => (
  <Stack
    {...props}
    attributes={{
      ...props.attributes,
      ...piiDataAttributes,
    }}
  >
    {children}
  </Stack>
);

export default PIIStack;
