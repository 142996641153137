import { BroadcastChannel } from 'broadcast-channel';

export type LogoutType = 'RESET_PASSWORD' | 'UNAUTHORIZED_SESSION';

export const broadcastLogout = (type: LogoutType) => {
  const logoutChannel = new BroadcastChannel('logout');

  logoutChannel.postMessage({ type });

  logoutChannel.close();
};
