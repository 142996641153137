import { KrakenError, KrakenValidationError } from '@/types/errors';

type ErrorWithMessage = {
  message: string;
};

const mapErrorCodeToType = {
  'KT-CT-1112': 'AUTH_HEADER_NOT_PROVIDED',
  'KT-CT-1124': 'ACCESS_TOKEN_EXPIRED',
  'KT-CT-1130': 'REFRESH_TOKEN_EXPIRED',
  'KT-CT-4111': 'UNABLE_TO_USE_EMAIL',
  'KT-CT-5450': 'INVALID_PASSWORD',
  'KT-CT-1143': 'UNAUTHORIZED_SESSION',
} as const;

type MapErrorCodeToType = typeof mapErrorCodeToType;
type ErrorTypeToCodeMapper = Record<
  MapErrorCodeToType[keyof MapErrorCodeToType],
  keyof MapErrorCodeToType
>;

export const errorTypeToCodeMapper = Object.entries(
  mapErrorCodeToType
).reduce<ErrorTypeToCodeMapper>(
  (partialErrorTypeToCodeMapper, [errorCode, errorType]) => ({
    ...partialErrorTypeToCodeMapper,
    [errorType]: errorCode,
  }),
  {} as ErrorTypeToCodeMapper
);

export const REFRESH_TOKEN_EXPIRED_MESSAGE =
  'The refresh token has expired.' as const;

// Type guard to check for message in error
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

type ErrorDescriptionWithType = {
  errorDescription: string;
  errorType?: string;
};

export function getErrorDescriptionWithType<
  TError extends KrakenError | KrakenValidationError,
>(error: TError): ErrorDescriptionWithType {
  const { extensions, message: errorMessage } = error;

  /**
   * Validation errors do not currently have Kraken error codes, but this is planned for the future.
   * Use this scope to manually map any validation messages to Kraken error codes.
   * Note: We have a discriminated union type of ObtainKrakenTokenValidationError within this block.
   */
  const fallbackErrorMessage =
    'Sorry! Something went wrong, please try again later.';

  if (errorMessage === 'An internal error occurred') {
    return {
      errorDescription: fallbackErrorMessage,
    };
  }
  if (!extensions) {
    if (errorMessage) {
      return {
        errorDescription: errorMessage,
      };
    }
    return {
      errorDescription: fallbackErrorMessage,
    };
  }
  if ('validationErrors' in extensions) {
    const validationMessage = extensions.validationErrors?.[0]?.message;
    const errorDescription = validationMessage ?? errorMessage;

    if (validationMessage === REFRESH_TOKEN_EXPIRED_MESSAGE) {
      return {
        errorDescription,
        errorType: mapErrorCodeToType['KT-CT-1130'],
      };
    }

    return {
      errorDescription,
    };
  }

  const { errorDescription: errorDescriptionExtension } = extensions;
  const errorDescription =
    errorDescriptionExtension && errorDescriptionExtension?.length > 0
      ? errorDescriptionExtension
      : errorMessage;

  if (extensions.errorCode) {
    return {
      errorDescription,
      errorType:
        mapErrorCodeToType[
          extensions.errorCode as keyof typeof mapErrorCodeToType
        ],
    };
  }

  return {
    errorDescription,
  };
}
