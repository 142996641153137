import { Stack } from '@krakentech/coral';

import AnimatedAlert from '@/components/AnimatedAlert';
import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';
import { useCommsPreferencesContext } from '@/context';
import { RelativelyPositionedDiv } from '@/styles/Utils.styled';

import {
  StyledQueuedAlertOuterContainer,
  StyledQueuedAlertInnerContainer,
} from './index.styled';

const CommsUpdateStatusAlerts = () => {
  const { setMutationStatusAlerts, mutationStatusAlerts } =
    useCommsPreferencesContext();

  // Remove the alert clicked on from the array
  const handleAlertCloseOnClick = (alertIndex: number) => {
    const newAlerts = mutationStatusAlerts.filter(
      (filteredAlert) => filteredAlert.alertIndex !== alertIndex
    );

    setMutationStatusAlerts(newAlerts);
  };

  // Remove the first alert from the array
  const handleAlertAutoclose = () => {
    const newAlerts = mutationStatusAlerts.slice(1);
    setMutationStatusAlerts(newAlerts);
  };

  const displayAlerts =
    mutationStatusAlerts[0]?.alertStatus !== ANIMATED_ALERT_STATUS.CLOSED;

  return (
    <>
      {displayAlerts && mutationStatusAlerts.length ? (
        <StyledQueuedAlertOuterContainer>
          <RelativelyPositionedDiv>
            <StyledQueuedAlertInnerContainer>
              <Stack direction="vertical" fullWidth gap="sm">
                {mutationStatusAlerts?.map(
                  (
                    { alertStatus, alertMessage, alertSeverity, alertIndex },
                    i: number
                  ) => (
                    <AnimatedAlert
                      alertStatus={alertStatus}
                      severity={alertSeverity}
                      handleAlertClose={() =>
                        handleAlertCloseOnClick(alertIndex!)
                      }
                      handleAlertAutoclose={handleAlertAutoclose}
                      key={i}
                      relativelyPositioned
                      alertClosureTimerLength={3000}
                    >
                      {alertMessage}
                    </AnimatedAlert>
                  )
                )}
              </Stack>
            </StyledQueuedAlertInnerContainer>
          </RelativelyPositionedDiv>
        </StyledQueuedAlertOuterContainer>
      ) : null}
    </>
  );
};

export default CommsUpdateStatusAlerts;
