import { useEffect } from 'react';
import {
  Link,
  Stack,
  Typography,
  useCoralBreakpoints,
} from '@krakentech/coral';
import { useFormikContext } from 'formik';

import PaymentScheduleCard from '@/components/billingAndPayment/PaymentScheduleCard';
import MutedText from '@/components/MutedText';
import PIITypography from '@/components/PII/PIITypography';
import { Body1Skeleton, Body2Skeleton } from '@/components/Skeletons';
import {
  DIRECT_DEBIT_SCHEDULE_CHOICES,
  PAYMENT_FREQUENCY_FIELD_OPTIONS,
} from '@/consts/paymentFrequency';
import { useDirectDebitContext } from '@/context';
import { useProposedDirectDebitPaymentSchedule } from '@/hooks/billsAndPayments/useProposedDirectDebitPaymentSchedule';
import { PaymentFrequencyFormValues } from '@/types/directDebit';
import { getNextNthDayOfTheMonth } from '@/utils/date';
import { formatCurrency } from '@/utils/formatters/currency';
import { formatFullDateShortMonth } from '@/utils/formatters/date';

import FixedScheduleError from '../FixedScheduleError';
import PaymentCardLoader from '../PaymentCardLoader';

const FixedMonthlyScheduleContent = () => {
  const { values } = useFormikContext<PaymentFrequencyFormValues>();
  const {
    data,
    isLoading: isLoadingProposedPaymentSchedule,
    isRefetching,
    refetch,
    isError: isErrorProposedPaymentSchedule,
  } = useProposedDirectDebitPaymentSchedule({
    scheduleType:
      typeof values.paymentFrequency !== 'undefined' &&
      values.paymentFrequency === PAYMENT_FREQUENCY_FIELD_OPTIONS.VARIABLE
        ? DIRECT_DEBIT_SCHEDULE_CHOICES.PAY_ON_RECEIPT_OF_BILL
        : DIRECT_DEBIT_SCHEDULE_CHOICES.MONTHLY,
  });
  const {
    setPaymentAmount,
    setFirstPossibleFixedSchedulePaymentDate,
    firstPossibleFixedSchedulePaymentDate,
  } = useDirectDebitContext();

  // Save payment amount and first payment date to context so it can be used later
  useEffect(() => {
    if (data) {
      setPaymentAmount(data.amount);
    }
  }, [data, setPaymentAmount]);

  useEffect(() => {
    if (data && values.paymentDay) {
      // Get what the user's next desired payment date is, based off their selected payment day. E.g. if today is January 1st 2024 and they want to pay on the 5th of each month, their nextDesiredPaymentDate is January 5th 2024
      const nextDesiredPaymentDate = getNextNthDayOfTheMonth(
        values.paymentDay.value,
        new Date(data.firstPossibleCollectionDate)
      );

      // Save this value to local state
      setFirstPossibleFixedSchedulePaymentDate(nextDesiredPaymentDate);
    }
  }, [data, values.paymentDay, setFirstPossibleFixedSchedulePaymentDate]);

  if (isLoadingProposedPaymentSchedule || isRefetching) {
    return <LoadingState />;
  }

  if (isErrorProposedPaymentSchedule) {
    return <FixedScheduleError refetch={refetch} />;
  }

  if (!firstPossibleFixedSchedulePaymentDate) {
    return null;
  }

  return (
    <>
      <Typography>
        This is calculated based on your usage and current balance
      </Typography>

      <PaymentScheduleCard title="Monthly payments">
        <Stack direction="vertical" alignItems="center">
          <PIITypography textAlign="center" variant="h1" component="p">
            {formatCurrency(data.amount)}
          </PIITypography>
          <PIITypography textAlign="center">
            will be collected every month, from your first payment on{' '}
            <strong>
              {formatFullDateShortMonth(firstPossibleFixedSchedulePaymentDate)}
            </strong>
          </PIITypography>
        </Stack>
      </PaymentScheduleCard>

      <MutedText>
        Want to pay a different amount?{' '}
        <Link href={process.env.NEXT_PUBLIC_HELP_LINK}> Please contact us</Link>
      </MutedText>
    </>
  );
};

const LoadingState = () => {
  const { isMaxMd } = useCoralBreakpoints();

  return (
    <>
      <Body1Skeleton width={isMaxMd ? undefined : 400} />

      <PaymentCardLoader title="Recommended amount" />

      <Body2Skeleton width={isMaxMd ? undefined : 400} />
    </>
  );
};

export default FixedMonthlyScheduleContent;
